import { uniqueId } from 'lodash'

import { POOL_ACTIVITY_REFRESH_TIMEOUT } from '../../config/settings'
import { Milliseconds, UTCDateInMs, UTCDateInSec } from '../../helpers/datetime'
import { ApiResponseList, FilterOptions, MarketType, TokenId, TransactionModel } from '../../model'
import { timestampFromUnixToWeb, timestampFromWebToUnix } from '../../utils'
import { FetcherHandler, MapDatahandler, PrepareParamsHandler } from './txsUpdater.types'

type TokenInfo = { id?: TokenId; marketType?: MarketType }

type ParamsKey = string

type RequestRecord = {
  id: string
  key: ParamsKey
  isLoading: boolean
  data: TransactionModel[] | undefined
  total: number
  params: RequestParams
  refreshTimestamp: UTCDateInSec | undefined
  isError: boolean
  errorMessage: string | null
  shouldRefresh: boolean // if true after fetch data should start timer for refresh
}

type RequestParams = {
  currentTokenRecord: TokenInfo

  filters: FilterOptions
  rowsToShow: number
  shouldRefresh: boolean
}

export type OnDataLoadedSuccessfulHandler = (props: {
  requestId: string
  data: TransactionModel[] // TODO: generic
  total: number
  refreshTimestamp: number
}) => void
type OnSetJustRefreshedHandler = () => void
type OnErrorHandler = (props: { errorMessage: string }) => void

type ParamsOnSetIsLoadingHandler = {
  isLoading: boolean
  requestId?: string
  shouldCleanData?: boolean
}

type OnSetIsLoadingHandler = (params: ParamsOnSetIsLoadingHandler) => void

class TxsLoader {
  name: string
  debug: boolean
  updaterTimeoutRef?: number // ability to clean current timer
  prepareParams: PrepareParamsHandler
  mapData: MapDatahandler
  requestsCache = new Map<string, RequestRecord>()
  refreshInterval = 30 * 1000 // TXN_DATA_REFRESH_INTERVAL
  refreshTimeout = POOL_ACTIVITY_REFRESH_TIMEOUT
  fetchDataFn: FetcherHandler
  onDataLoadedSuccessful: OnDataLoadedSuccessfulHandler
  onSetJustRefreshed: OnSetJustRefreshedHandler
  onError: OnErrorHandler
  onSetIsLoading: OnSetIsLoadingHandler

  constructor({
    name,
    debug = false,
    onDataLoadedSuccessful,
    onSetJustRefreshed,
    onError,
    onSetIsLoading,
    prepareParams,
    mapData,
    fetchDataFn,
    refreshInterval,
  }: {
    name: string
    debug?: boolean
    onDataLoadedSuccessful: OnDataLoadedSuccessfulHandler
    onSetJustRefreshed: OnSetJustRefreshedHandler
    onError: OnErrorHandler
    onSetIsLoading: OnSetIsLoadingHandler
    prepareParams: PrepareParamsHandler
    mapData: MapDatahandler
    fetchDataFn: FetcherHandler
    refreshInterval?: number
  }) {
    this.name = name
    this.prepareParams = prepareParams
    this.mapData = mapData
    this.fetchDataFn = fetchDataFn
    this.onDataLoadedSuccessful = onDataLoadedSuccessful
    this.onSetJustRefreshed = onSetJustRefreshed
    this.onError = onError
    this.onSetIsLoading = onSetIsLoading
    this.debug = debug
    if (refreshInterval) {
      this.refreshInterval = refreshInterval
    }
  }

  log(...args: unknown[]): void {
    if (!this.debug) {
      return
    }
    // eslint-disable-next-line no-console
    console.log(this.name, ...args)
  }

  handleSetIsLoading(requestItem: RequestRecord, params: ParamsOnSetIsLoadingHandler): void {
    requestItem.isLoading = params.isLoading
    this.onSetIsLoading(params)
    this.log('handleSetIsLoading', requestItem)
  }

  startNewTimer({ requestItem }: { requestItem: RequestRecord }): void {
    const refreshDurationRemain = this.refreshInterval
    let timerRef = -1
    this.updaterTimeoutRef = window.setTimeout(() => {
      this.log('invoke timer fn', timerRef, requestItem)
      this.fetchTxData(requestItem)
    }, refreshDurationRemain)
    timerRef = this.updaterTimeoutRef
    this.log('create new timer', this.updaterTimeoutRef, requestItem)
  }

  clearUpdatingTimer(): void {
    if (!this.updaterTimeoutRef) {
      this.log('no timer')
      return
    }
    this.log('clear', this.updaterTimeoutRef)
    clearTimeout(this.updaterTimeoutRef)
    this.updaterTimeoutRef = undefined
  }

  stop = (): void => {
    this.log('stop')
    this.clearUpdatingTimer()
  }

  makeHash = (params: RequestParams): string =>
    JSON.stringify({
      currentTokenRecord: params.currentTokenRecord,
      filters: params.filters,
      rowsToShow: params.rowsToShow,
    })

  createNewRequestItem = (key: ParamsKey, params: RequestParams): RequestRecord => ({
    id: uniqueId(),
    key, // fast search if ypu have record item
    isLoading: false,
    data: undefined,
    total: 0,
    params,
    isError: false,
    errorMessage: null,
    refreshTimestamp: undefined,
    shouldRefresh: params.shouldRefresh,
  })

  getRequestItem(params: RequestParams): RequestRecord {
    const key = this.makeHash(params)
    const data = this.requestsCache.get(key)
    if (data) {
      this.log('found', key, data)
      return data
    }

    const requestItem = this.createNewRequestItem(key, params)
    this.requestsCache.set(key, requestItem)
    this.log('create new', requestItem)
    return requestItem
  }

  getNewRefreshTimestamp = (): Milliseconds =>
    timestampFromWebToUnix(new Date().getTime() + this.refreshInterval) as UTCDateInSec

  handleNewData({
    response,
    requestItem,
  }: {
    requestItem: RequestRecord
    response: ApiResponseList<TransactionModel>
  }): void {
    this.log('handleNewData')
    const rawData = response.response?.data
    const total = response.response?.total
    if (!Array.isArray(rawData)) {
      throw new Error('invalid data')
    }
    const { currentTokenRecord } = requestItem.params
    const resultData = this.mapData({
      rawData,
      tokenId: currentTokenRecord.id,
      marketType: currentTokenRecord.marketType,
    })
    // TODO: mb replace this logic to TableWithFilters
    const refreshTimestamp = this.getNewRefreshTimestamp()

    this.requestsCache.set(requestItem.key, {
      ...requestItem,
      data: resultData,
      total,
      refreshTimestamp,
      isLoading: false,
      errorMessage: response.errorMessage || null,
      isError: response.isError || false,
    })

    this.log('handleNewData -> update date', requestItem)

    this.onDataLoadedSuccessful({
      requestId: requestItem.id,
      data: resultData,
      total,
      refreshTimestamp,
    })

    // 2 sec  refresh
    window.setTimeout(() => {
      this.onSetJustRefreshed()
    }, this.refreshTimeout)
  }

  handleError({
    errorMessage,
    requestItem,
  }: {
    errorMessage: string
    requestItem: RequestRecord
  }): void {
    const refreshTimestamp = this.getNewRefreshTimestamp()

    this.requestsCache.set(requestItem.key, {
      ...requestItem,
      refreshTimestamp: refreshTimestamp,
      isLoading: false,
      errorMessage: errorMessage || null,
      isError: true,
    })
    this.onError({ errorMessage })
  }

  async fetchTxData(requestItem: RequestRecord): Promise<void> {
    this.log('start fetchTxData', requestItem)
    const { currentTokenRecord, filters, rowsToShow } = requestItem.params
    let response: ApiResponseList<TransactionModel>

    try {
      const options = this.prepareParams({ currentTokenRecord, filters, rowsToShow })

      response = await this.fetchDataFn(options)
      this.log('after fetch', requestItem, response)
      if (response.isError) {
        throw new Error(response.errorMessage)
      }
      // set refreshTimestamp now + 30
      this.handleNewData({ requestItem, response: response })
    } catch (error) {
      const errorMessage = (error as unknown as Error).message as string
      this.handleError({ requestItem, errorMessage })
    }
    const updatedRequestItem = this.requestsCache.get(requestItem.key)

    if (!updatedRequestItem) {
      this.log('no requested item', requestItem.key)
    }

    if (updatedRequestItem?.shouldRefresh) {
      this.startNewTimer({ requestItem: updatedRequestItem })
    }
  }

  getRefreshDuration = (requestItem: RequestRecord): UTCDateInMs | undefined =>
    requestItem.refreshTimestamp
      ? timestampFromUnixToWeb(requestItem.refreshTimestamp) - new Date().getTime()
      : undefined

  // true - expired
  getExpiredStatus = (requestItem: RequestRecord): boolean =>
    Number(this.getRefreshDuration(requestItem)) <= 0

  async requestData(params: RequestParams): Promise<void> {
    this.log('requestData', params)

    // get exsisted or create
    const requestItem = this.getRequestItem(params)

    this.clearUpdatingTimer()

    // if data expired isExpired(requestItem)
    const isDataExpired = this.getExpiredStatus(requestItem)
    this.log(`isDataExpired: ${isDataExpired}`, requestItem)
    // if no data and data is not loading
    if ((!requestItem.data && !requestItem.isLoading) || isDataExpired) {
      this.log('should fetch data', requestItem)
      // intentionally repeat twice here and below
      this.handleSetIsLoading(requestItem, {
        isLoading: true,
        requestId: requestItem.id,
        shouldCleanData: true,
      })
      this.fetchTxData(requestItem)
      return
    }

    // cached data
    if (!isDataExpired && requestItem.data && requestItem.refreshTimestamp) {
      // eslint-disable-next-line no-console
      this.log('cached data', requestItem)
      this.handleSetIsLoading(requestItem, {
        isLoading: true,
        requestId: requestItem.id,
        shouldCleanData: true,
      })
      this.startNewTimer({ requestItem })
      this.onDataLoadedSuccessful({
        requestId: requestItem.id,
        data: requestItem.data,
        refreshTimestamp: requestItem.refreshTimestamp,
        total: requestItem.total,
      })
    }
  }
}

export default TxsLoader
