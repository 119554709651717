import isEqual from 'lodash.isequal'
import { useEffect, useRef } from 'react'

import { usePrevious } from '../../hooks'
import { useComponentDidMount } from '../../hooks/useComponentDidMount'
import { FilterOptions, MarketType, TokenId } from '../../model'
import TxsLoader from './TxsLoader'
import {
  setError,
  setIsJustRefreshed,
  setIsLoading,
  setPoolActivityData,
  TxsLoaderAction,
} from './txsLoaderReducer'
import { FetcherHandler, MapDatahandler, PrepareParamsHandler } from './txsUpdater.types'

const useLoadTransactions = ({
  currentTokenRecord,
  dispatch,
  filters,
  rowsToShow,
  shouldStopRefresh,
  prepareParams,
  fetchDataFn,
  mapData,
  name,
}: {
  currentTokenRecord: { id?: TokenId; marketType?: MarketType }
  dispatch: React.Dispatch<TxsLoaderAction>
  filters: FilterOptions
  rowsToShow?: number
  shouldStopRefresh: boolean
  prepareParams: PrepareParamsHandler
  fetchDataFn: FetcherHandler
  mapData: MapDatahandler
  name: string
}): void => {
  const prevCurrentTokenRecord = usePrevious(currentTokenRecord)
  const prevFilters = usePrevious(filters)
  const prevShouldStopRefresh = usePrevious(shouldStopRefresh)

  const isInitLoaded = useRef<boolean>(false)
  const txsLoaderRef = useRef<TxsLoader | undefined>(undefined)

  // init TxsLoader loader
  useComponentDidMount(() => {
    txsLoaderRef.current = new TxsLoader({
      prepareParams,
      mapData,
      onDataLoadedSuccessful: (dataProps) => dispatch(setPoolActivityData(dataProps)),
      onSetJustRefreshed: () => dispatch(setIsJustRefreshed(false)),
      onError: ({ errorMessage }) => dispatch(setError({ errorMessage })),
      onSetIsLoading: (params) => dispatch(setIsLoading(params)),
      name,
      fetchDataFn,
    })

    return (): void => {
      if (!txsLoaderRef.current) {
        return
      }

      txsLoaderRef.current.stop()
    }
  })

  useEffect(() => {
    // not inited status
    if (!rowsToShow || !currentTokenRecord.id || !txsLoaderRef.current) {
      return
    }

    const isFiltersNotChanged = isEqual(filters, prevFilters)
    const isCurrentTokenNotChanged = isEqual(currentTokenRecord, prevCurrentTokenRecord)

    // this condition enabled only if in inited state
    if (
      isInitLoaded.current &&
      isCurrentTokenNotChanged &&
      isFiltersNotChanged &&
      shouldStopRefresh === prevShouldStopRefresh
    ) {
      return
    }

    // set inited state
    isInitLoaded.current = true
    if (shouldStopRefresh) {
      txsLoaderRef.current.stop()
      return
    }

    txsLoaderRef.current.requestData({
      currentTokenRecord,
      filters,
      rowsToShow,
      shouldRefresh: true,
    })
  }, [
    dispatch,
    currentTokenRecord,
    filters,
    rowsToShow,
    prevCurrentTokenRecord,
    prevFilters,
    shouldStopRefresh,
    prevShouldStopRefresh,
  ])
}
export default useLoadTransactions
