import { FilterOptions, TransactionModel } from '../../model'

enum TxsLoaderActions {
  setFilters,
  setIsLoading,
  setRowsToShow,
  setData,
  setIsJustRefreshed,
  setActiveFilter,
  setError,
}

type SetFiltersAction = {
  type: TxsLoaderActions.setFilters
  payload: Partial<FilterOptions>
}

type SetIsJustRefreshedAction = {
  type: TxsLoaderActions.setIsJustRefreshed
  payload: boolean
}

type SetIsLoadingAction = {
  type: TxsLoaderActions.setIsLoading
  payload: { isLoading: boolean; requestId?: string; shouldCleanData?: boolean }
}

type SetRowsToShowAction = {
  type: TxsLoaderActions.setRowsToShow
  payload: number
}

type SetDataAction = {
  type: TxsLoaderActions.setData
  payload: {
    requestId: string
    data: TransactionModel[]
    refreshTimestamp: number
  }
}

type SetErrorAction = {
  type: TxsLoaderActions.setError
  payload: { errorMessage: string }
}

export type TxsLoaderAction =
  | SetIsLoadingAction
  | SetRowsToShowAction
  | SetDataAction
  | SetIsJustRefreshedAction
  | SetErrorAction
  | SetFiltersAction

/* Actions */

export const setFilters = (payload: Partial<FilterOptions>): SetFiltersAction => ({
  type: TxsLoaderActions.setFilters,
  payload,
})

export const setIsLoading = (payload: {
  isLoading: boolean
  requestId?: string
  shouldCleanData?: boolean
}): SetIsLoadingAction => ({
  type: TxsLoaderActions.setIsLoading,
  payload,
})

export const setRowsToShow = (amount: number): SetRowsToShowAction => ({
  type: TxsLoaderActions.setRowsToShow,
  payload: amount,
})

export const setPoolActivityData = (payload: {
  requestId: string
  data: TransactionModel[]
  refreshTimestamp: number
}): SetDataAction => ({
  type: TxsLoaderActions.setData,
  payload,
})

export const setIsJustRefreshed = (value: boolean): SetIsJustRefreshedAction => ({
  type: TxsLoaderActions.setIsJustRefreshed,
  payload: value,
})

export const setError = (payload: { errorMessage: string }): SetErrorAction => ({
  type: TxsLoaderActions.setError,
  payload,
})

export type TxsLoaderReducerState = {
  currentRequestId?: string
  isLoading: boolean
  rowsToShow?: number
  filters: FilterOptions
  currentData?: TransactionModel[]
  previousData?: TransactionModel[]
  refreshTimestamp?: number
  isJustRefreshed: boolean
}

const txLoaderReducer = (
  state: TxsLoaderReducerState,
  action: TxsLoaderAction
): TxsLoaderReducerState => {
  switch (action.type) {
    case TxsLoaderActions.setFilters:
      return {
        ...state,
        filters: {
          ...action.payload,
        },
      }
    case TxsLoaderActions.setIsLoading:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        currentRequestId: action.payload.requestId,
        currentData: !action.payload.shouldCleanData ? state.currentData : undefined,
        previousData: !action.payload.shouldCleanData ? state.previousData : undefined,
      }
    case TxsLoaderActions.setRowsToShow:
      return { ...state, rowsToShow: action.payload }
    case TxsLoaderActions.setData:
      // request queue manager logic
      if (action.payload.requestId !== state.currentRequestId) {
        return state
      }
      return {
        ...state,
        isLoading: false,
        currentData: action.payload.data,
        refreshTimestamp: action.payload.refreshTimestamp,
        isJustRefreshed: true,
        previousData: state.currentData,
      }
    case TxsLoaderActions.setIsJustRefreshed:
      return { ...state, isJustRefreshed: action.payload }
    case TxsLoaderActions.setError:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

export default txLoaderReducer
